.dropdown-container {
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 10px 0;
}

.dropdown-selector {
	height: 3em;
	width: 40%;
	padding: 0 10px;
	border-radius: 5px;
	background: rgba(30, 30, 30, 1);
	color: white;
	font-size: 16px;
}

@media screen and (max-width: 767px) {
	.dropdown-selector {
		font-size: 75%;
	}
}


