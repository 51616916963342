.textarea-collapse {
	width: 100%;
	margin-bottom: 25px;

	&-panel {
		background-color: #d9d9d9;
		font-style: italic;
	}

	.bpnyc-autoSaveTextArea {
		.bpnyc-autoSave {
			textarea.bpnyc-form-input {
				width: 100%;
				height: 120px;
				margin: 0;
				border: none;
				resize: none;
				font-family: Arial, Helvetica, sans-serif;
				padding: 8px;
				box-sizing: border-box;
			}
			.bpnyc-autoSave-border {
				height: calc(100% - 5px);
				width: calc(100% + 5px);
			}
		}
	}
}
