
.input {
	&-container {
		margin: 5px 2px;
	}

	&-label {
		// background-color: black;
		// color: white;
		padding: 0 5px;
	}

	&-inputContainer {
		// width: 170px;
		display: inline-block;
		flex-grow: 1;

		& input {
			vertical-align: middle;
			font-size: 16px;
		}
	}

	// &-has-caret {
	// 	padding-right: 20px;
	// }

	&-inputContainer > div > .anticon {
		display: inline-block;
		position: relative;
		// right: 20px;
		top: 2px;
	}
}
