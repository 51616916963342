
.searchableInput {
	&-dropdown {
		display: flex;
		flex-direction: column;
		position: absolute;
		// text-align: center;
		overflow: hidden;
		background-color: rgba(50,50,50,0.9);
		color: white;
		border-radius: 5px;
		z-index: 10;
	}

	&-dropdown-option {
		cursor: pointer;
		padding: 5px 0px 5px 10px;
		border-bottom: 1px solid black;
		font-size: 15px;

		&:hover {
			background-color: black;
		}
	}
}
