.textinput{
	display: "flex";
	flex-direction: "column";
	width: 100%;

	$primary-color: #0c6aae;

	input.bpnyc-controlledInput {
		width: 100%;
		box-sizing: border-box;
		border: 1px solid #e0e0e0;
		border-radius: 8px;
		color: #3e3e40;
		transition: none;
		-webkit-transition: none;
		padding: 12px 16px;
		font-weight: 400;
		font-size: 15px;
		height: 50px;
		outline: none;
		overflow: visible;
		text-transform: none;
		&:focus {
			border: 1px solid $primary-color;
			outline: none;
			box-shadow: none;
		}
	}
}

@media screen and (max-width: 767px) {
	.textinput{
		width: 100%;
	}
}
