body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.submit-container {
	display: flex;
}

button.submit-button {
	margin: 10px auto;
	background-color: goldenrod;
}


div.bpnyc-modal-container {
	& section.bpnyc-modal-body {
		& div.bpnyc-modal-body-container {
			text-align: center;

			& .bpnyc-button-button {
				margin: 0 auto;
			}
		}
	}
}

.worksheet{
	&-container{
		width: 90%;
		display: flex;
		flex-direction: column;
		margin: auto;
	}

	

	&-header{		
		margin: 25px 0 0 0;
		&-text {
			font-size: 4vh;
			margin: 0;
		}
		&-instructions {
			margin: 15px 0 25px 0;
		}
	}
}



@media screen and (max-width: 767px) {
	.worksheet{
		&-header{	
			&-text{	
				font-size: 3vh;
			}
		}
	}
}



input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}