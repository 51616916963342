
table {
	tr {
		background-color: lightyellow;
		text-align: center;
	}
	
	th {
		background-color: lightgray;
	}
}

@media screen and (max-width: 767px) {
	table{
		font-size: 3vw;
	}
}