
.form {
	&-container {
		width: 100%;
		margin-bottom: 50px;
	}

	&-disclaimer {
		font-size: 12px;
		font-style: italic;
	}

	&-scenarioTotal {
		text-align: center;
		font-weight: 500;
		font-size: 4vw;
		margin: 2vw 0;
	}

	&-scoreLabel {
		margin: 2vw 0;
		display: flex;
		justify-content: space-around;

		& > span {
			margin: 0 1.2vw;
			font-size: 4vw;
		}

		&-container {
			display: flex;
			flex-direction: column;
			height: 100%;
			justify-content: center;
		}
	}

	&-section {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		&-header {
			color: white;
			font-weight: 700;
			padding: 15px;
			border-radius: 10px;
			margin-top: 15px;
			margin-bottom: 5px;
			.sub-label {
				font-size: 0.8em;
			}
		}

		&-label {
			margin: 2vw auto;
		}

		&-row {
			display: flex;
		}

		&-left {
			padding: 0 10px;
			width: 65%;
		}
		&-right {
			padding: 0 10px;
			width: 30%;
			display: inline-block;
		}

		&-overallScore {
			background-color: #EEE;
		}

		& > textarea {
			border: none;
			// cursor: pointer;
		}
		&-inverted > &-header {
			background-color: #000;
			color: #FFF;
		}
		&-notes > &-header {
			background-color: #FFF;
		}
		&-notes > &-header > div {
			border: 0;
			border-bottom: 1px solid black;
		}

		
	}

	&-table {
		width: 90%;
		
	}

	&-instruction {
		padding: 10px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		> span {
			width: 65%;
			font-style: italic;
			font-size: 1em;
		}
	}
}

h2.form-section-label {
	font-size: 4vw;
	margin: 2vw 0;
}

// .form-section-inverted > .form-section-header {
// 	background-color: #000;
// 	color: #FFF;
// }

@media screen and (min-width: 600px) {
	h2.form-section-label {
		font-size: 30px;
	}

	.form-scenarioTotal {
		font-size: 30px;
	}
}

@media screen and (max-width: 767px) {
.form{
	&-instruction {
		> span {
			font-size: 2.5vw;
		}
	}

	&-container {
		margin-bottom: 25px;
	}
}
}