
.yesno {
	&-container {
		margin: 2vw auto;
		width: 100%;
		display: flex;
		justify-content: center;
	}
	&-option {
		display: flex;
		cursor: pointer;
	}
	&-select {
		border: 1px solid darkgray;
		border-radius: 50%;
		width: 3vw;
		height: 3vw;
		display: flex;

		& > .anticon {
			margin: 0.5vw;
			color: #FFF;
			font-size: 2.5vw;
		}

		&.selected {
			background-color: #0c6aae;
		}
	}
	&-label {
		margin: 0;
		padding: 0 1.5vw;
		font-size: 3.5vw;
	}
}

@media screen and (min-width: 600px) {
	.yesno-label {
		font-size: 18px;
	}
}