
.radiobutton {
	&-container {
		margin: 0;
		width: 100%;
		display: flex;
		align-items: center;

		&-options{
			display: flex;
			width: 50%;
			margin-left: 25px;
			justify-content: space-evenly;
		}
	}
	
	&-prompt {
		margin: 0;
	}
	&-option {
		display: flex;
		align-items: center;
		width: 30%;
		cursor: pointer;
	}
	&-select {
		border: 2px solid #e0e0e0;
		border-radius: 50%;
		width: 1.5vw;
		height: 1.5vw;

		& > .anticon {
			margin: 0.25vw;
			color: #FFF;
			font-size: 2.5vw;
			display: flex;
		}

		&.selected {
			background-color: #189AD3;
		}
	}
	&-label {
		margin-left: 5px;
	}
}


@media screen and (max-width: 767px) {
	.radiobutton{
		&-container{
			flex-direction: column;
			align-items: flex-start;
			
			&-options{
				width: 100%;
				flex-direction: column;
				justify-content: space-evenly;
				margin: 0;
			}
		}

		&-option{
			width: 100%;
		}

		&-select {
			border: 2px solid #e0e0e0;
			border-radius: 50%;
			width: 3vw;
			height: 3vw;
		}	

		&-prompt {
			margin: 15px 0 0 0;
		}
	}
}