
.twoOption {
	&-container {
		margin: 2vw auto;
		width: 100%;
		display: flex;
		justify-content: space-evenly;
	}
	&-option {
		display: flex;
		cursor: pointer;
	}
	&-select {
		border: 1px solid darkgray;
		width: 3vw;
		height: 3vw;
		display: flex;

		& > .anticon {
			margin: 0.5vw;
			color: #FFF;
			font-size: 2.5vw;
		}

		&.selected {
			background-color: #0c6aae;
		}
	}
	&-label {
		margin: 0;
		padding: 0 1.5vw;
		font-size: 3.5vw;
	}

	&-choicesContainer {
		width: 30%;
		height: 100%;
		display: flex;
		justify-content: space-evenly;
	}

	&-choice {
		margin: 0;
		font-size: 1.5vw;
		align-self: center;
		font-weight: bold;
	}

}

@media screen and (min-width: 600px) {
	.twoOption-label {
		font-size: 18px;
	}
}

@media screen and (max-width: 767px) {
	.twoOption{
		&-choice {
			font-size: 2vw;
			padding-left: 10px;
		}
	}
}