.header {
	&-text {
		font-size: 4vw;
		text-align: center;
	}

	&-fields {
		width: 80%;
		border: 1px solid black;
		border-bottom: none;
		margin: 0 auto;
		overflow-x: hidden;

		display: flex;
		
		&-first {
			width: 40%;
			background-color: red;
		}

		&-last {
			border-bottom: 1px solid black;
		}

		& div.input-container {
			width: 100%;
			display: flex;

			& input {
				width: 50vw;
			}
		}
	}
}