.textarea{
	width: 100%;

	.bpnyc-autoSaveTextArea {
		.bpnyc-autoSave {
			textarea.bpnyc-form-input {
				width: 100%;
				height: 225px;
				resize: none;
				border: 1px solid #e0e0e0;
				border-radius: 8px;
				font-family: Arial, Helvetica, sans-serif;
				padding: 15px;
				box-sizing: border-box;
			}
			.bpnyc-autoSave-border {
				height: calc(100% - 5px);
				width: calc(100% + 5px);
			}
		}
	}
}