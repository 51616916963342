
div.formDefinition {
	flex-direction: column;
	justify-content: center;
	margin: 5px auto;
	width: 90%;
	border-top: 1px solid black;

	& > p {
		margin: 0;
		margin-top: 5px;
		text-align: center;
	}

}
div.formDefinition > p:nth-child(1) {
	margin: 5px 0;
	font-size: 1em;
}